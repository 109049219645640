<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Tambah Admin Fee
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules"
        v-slot="{ invalid }">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Posisi <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Posisi"
                      rules="required"
                    >
                      <v-select
                        class="w-100"
                        placeholder="Pilih Posisi"
                        :label="'name'"
                        :clearable="true"
                        :options="listSkills"
                        :reduce="skill => skill.id"
                        v-model="skillId"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Deskripsi <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Deskripsi"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="description"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Jenis Potongan <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Jenis Potongan"
                      rules="required"
                    >
                      <v-select
                        v-model="admin_fee_type"
                        label="label"
                        :options="admin_fee_type_option"
                        :reduce="option => option.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <template #label>
                      Biaya Standar <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Biaya Standar"
                      rules="required|integer"
                    >
                      <money
                        v-model="admin_fee"
                        v-bind="money"
                        class="form-control"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center mb-[20px]">
          <button
            class="ml-auto px-1 button-primary py-[8px]"
            @click="showAddTalent = true"
          >
            <strong v-if="!discounts.length">Tambah</strong>
            <strong v-else>Edit</strong>
          </button>
        </div>
        <BTable
          :items="discounts"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton class="btn-icon mr-50" size="sm" variant="flat-warning"
                @click="selectItem(data.item.id, true)"
                v-b-modal.modalDelete
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                  alt="Komerce"
                />
              </BButton>
            </div>
          </template>
        </BTable>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            v-b-modal.modalConfirmation
          >
            Kembali
          </button>
          <button
            class="button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalConfirmation />
    <ModalAdd
      v-if="showAddTalent"
      @addTalent="handleAdd"
      @close="showAddTalent = false"
      :discountExist="discounts"
    />
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  max,
  numeric,
  integer,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { capitalize } from '@/libs/helpers'
import { alertError, alertSuccess } from '@toast'
import { Money } from 'v-money'
import { tableDiscount } from './config'
import ModalConfirmation from './ModalConfirmation.vue'
import ModalAdd from './ModalAdd.vue'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalConfirmation,
    ModalAdd,
    Money,
  },
  data() {
    return {
      required,
      min,
      max,
      numeric,
      capitalize,
      fields: tableDiscount,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      id: this.$route.params.id,
      alertError,
      alertSuccess,
      listSkills: [],
      discounts: [],
      skillId: null,
      description: '',
      showAddTalent: false,

      admin_fee_type_option: [
        {
          value: 'percentage',
          label: 'Presentase %',
        },
        {
          value: 'rp',
          label: 'Nominal Rp',
        },
      ],
      minimum_income: '',
      admin_fee_type: '',
      sharing_fee_value: '',
      admin_fee: '',
      money: {
        thousands: '.',
        prefix: 'Rp ',
        precision: 0,
        masked: false,
      },
    }
  },
  mounted() {
    this.getListSkills()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        skill_id: this.skillId,
        description: this.description,
        admin_fee_discount_type: this.admin_fee_type,
        admin_fee: this.admin_fee,
        admin_fee_discounts: this.discounts,
      }

      const url = 'v1/admin_fees/store'
      await komtimAxiosIns
        .post(url, data)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$router.push('/admin-fees')
          this.loadingSubmit = false
        })
        .catch(error => {
          this.alertError(error)
          this.loadingSubmit = false
        })
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkills = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    handleAdd(payload) {
      this.discounts = payload
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
